var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cassie-vertical-md"},[_c('BasicForm',{attrs:{"item":_vm.item,"name-key":_vm.nameKey},on:{"update:item":_vm.updateItem}}),_c('v-radio-group',{staticClass:"mt-0",attrs:{"value":_vm.item.isInternal,"hide-details":""},on:{"change":function($event){return _vm.updateItemProperty('isInternal', $event)}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Is an Internal System? ")]},proxy:true}])},[_c('div',{staticClass:"d-flex"},[_c('v-radio',{staticClass:"my-0 mr-4",attrs:{"label":"Yes","value":true}}),_c('v-radio',{staticClass:"my-0",attrs:{"label":"No","value":false}})],1)]),_c('Dropdown',{attrs:{"value":_vm.item.systemTypeId,"items":_vm.systemTypes.map(function (ref) {
	var systemTypeId = ref.systemTypeId;
	var systemTypeName = ref.systemTypeName;

	return ({ value: systemTypeId, text: systemTypeName });
}),"label":"Type *","rules":"required"},on:{"input":function($event){return _vm.updateItemProperty('systemTypeId', $event)}}}),_c('Dropdown',{attrs:{"value":_vm.item.organisationId,"items":_vm.organisations.map(function (ref) {
	var organisationId = ref.organisationId;
	var organisationName = ref.organisationName;

	return ({ value: organisationId, text: organisationName });
}),"label":"Organisation *","rules":"required"},on:{"input":function($event){return _vm.updateItemProperty('organisationId', $event)}}}),_c('CitySelector',{attrs:{"country-id":_vm.item.countryId,"city-id":_vm.item.cityId,"countries":_vm.countries,"required":""},on:{"update:countryId":function($event){return _vm.updateItemProperty('countryId', $event)},"update:cityId":function($event){return _vm.updateItemProperty('cityId', $event)}}}),_c('Dropdown',{attrs:{"value":_vm.item.retentionPeriodId,"items":_vm.retentionPeriods.map(function (ref) {
	var retentionPeriodId = ref.retentionPeriodId;
	var retentionPeriodName = ref.retentionPeriodName;

	return ({ value: retentionPeriodId, text: retentionPeriodName });
}),"label":"Retention Period *","rules":"required"},on:{"input":function($event){return _vm.updateItemProperty('retentionPeriodId', $event)}}}),_c('Dropdown',{attrs:{"value":_vm.item.cassieTypeId,"items":_vm.cassieSubSystemTypes.map(function (ref) {
	var cassieSubSystemTypeId = ref.cassieSubSystemTypeId;
	var cassieSubSystemTypeName = ref.cassieSubSystemTypeName;

	return ({ value: cassieSubSystemTypeId, text: cassieSubSystemTypeName });
}),"label":"Cassie Type Id *","rules":"required"},on:{"input":function($event){return _vm.updateItemProperty('cassieTypeId', $event)}}}),_c('Dropdown',{attrs:{"value":_vm.item.dpoContactId,"items":_vm.contactOptions,"label":"Data Protection Officer (DPO) *","rules":"required"},on:{"input":function($event){return _vm.updateItemProperty('dpoContactId', $event)}}}),_c('Dropdown',{attrs:{"value":_vm.item.contactId,"items":_vm.contactOptions,"label":"Contact *","rules":"required"},on:{"input":function($event){return _vm.updateItemProperty('contactId', $event)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }