<template>
	<div class="cassie-vertical-md">
		<BasicForm
			:item="item"
			:name-key="nameKey"
			@update:item="updateItem"
		/>
		<Dropdown
			:value="item.organisationTypeId"
			:items="organisationTypes.map(({ organisationTypeId, organisationTypeName }) => ({ value: organisationTypeId, text: organisationTypeName }))"
			label="Type *"
			@input="updateItemProperty('organisationTypeId', $event)"
		/>
	</div>
</template>

<script>
import Dropdown from '../../../../../../shared/components/dropdown.vue'
import BasicForm from './basic-form.vue'
import { getProcessesAndLookupData } from '../../../../../../shared/utils/api/processing.js'

export default {
	components: { Dropdown, BasicForm },
	props: {
		item: Object,
		nameKey: String,
		updateItem: Function,
		updateItemProperty: Function
	},
	data () {
		return {
			organisationTypes: []
		}
	},
	async created () {
		const { data: { lookUpData: { organisationTypes } } } = await getProcessesAndLookupData()
		this.organisationTypes = organisationTypes
	}
}
</script>
