<template>
	<div class="cassie-vertical-md">
		<Dropdown
			:value="item.brandId"
			:items="brandOptions"
			custom-sort
			:label="'Assign to Brand *' | useLabels"
			rules="required"
			@input="updateItem('brandId', $event)"
		/>
		<TextField
			:value="item[nameKey]"
			label="Name *"
			rules="required"
			@input="updateItem(nameKey, $event)"
		/>
	</div>
</template>

<script>
import Dropdown from '../../../../../../shared/components/dropdown.vue'
import TextField from '../../../../../../shared/components/text-field.vue'
import { brandOptions } from '../../../../../../shared/state/brands.js'
export default {
	components: { TextField, Dropdown },
	props: {
		item: Object,
		nameKey: String
	},
	setup () {
		return {
			brandOptions
		}
	},
	methods: {
		updateItem (property, value) {
			this.$emit('update:item', { ...this.item, [property]: value })
		}
	}
}
</script>
