export const DATA_SUBJECT_CATEGORIES = 'DATA_SUBJECT_CATEGORIES'
export const DATA_TRANSFER_TYPES = 'DATA_TRANSFER_TYPES'
export const CONTACTS = 'CONTACTS'
export const LEGAL_BASIS = 'LEGAL_BASIS'
export const ORGANISATIONS = 'ORGANISATIONS'
export const ORGANISATION_TYPES = 'ORGANISATION_TYPES'
export const PII_CATEGORIES = 'PII_CATEGORIES'
export const PROCESS_OWNERS = 'PROCESS_OWNERS'
export const PROCESS_PURPOSES = 'PROCESS_PURPOSES'
export const RETENTION_PERIODS = 'RETENTION_PERIODS'
export const SPECIAL_PII_CATEGORIES = 'SPECIAL_PII_CATEGORIES'
export const SECURITY_MEASURE_TYPES = 'SECURITY_MEASURE_TYPES'
export const SCHEDULE_TYPES = 'SCHEDULE_TYPES'
export const SYSTEM_TYPES = 'SYSTEM_TYPES'
export const SYSTEMS = 'SYSTEMS'

const createdDate = {
	value: 'createdDate',
	text: 'Created Date'
}

const brand = {
	value: 'brandName',
	text: 'Brand'
}

const action = {
	value: 'action',
	text: 'Action'
}

const option = (value, text) => ({ value, text })

const contactHeaders = [
	option('contactId', 'ID'),
	option('contactFullName', 'Contact Name'),
	option('jobTitle', 'Job Title'),
	option('emailAddress', 'Email Address'),
	option('telNo', 'Telephone No.'),
	option('mobileNo', 'Cell/Mobile'),
	option('isDPO', 'Is DPO?'),
	option('isExternal', 'Is External?'),
	brand,
	createdDate,
	action
]

const itemMapper = item => item

export const lookUpDataConfigMap = {
	[DATA_SUBJECT_CATEGORIES]: {
		singularText: 'Data Subject Category',
		pluralText: 'Data Subject Categories',
		modalComponentName: 'BasicForm',
		idKey: 'dataSubjectCategoryId',
		nameKey: 'dataSubjectCategoryName',
		optionsIdKey: 'dataSubjectCategories',
		typeId: 'dataSubjectCategories',
		lookupObjectName: 'DATASUBJECTCATEGORIES',
		tableHeaders: [
			option('dataSubjectCategoryId', 'ID'),
			option('dataSubjectCategoryName', 'Category Name'),
			brand,
			createdDate,
			action
		],
		itemMapper
	},
	[DATA_TRANSFER_TYPES]: {
		singularText: 'Data Transfer Type',
		pluralText: 'Data Transfer Types',
		modalComponentName: 'NameOnlyForm',
		idKey: 'dataTransferTypeId',
		nameKey: 'dataTransferTypeName',
		optionsIdKey: 'dataTransferTypes',
		typeId: 'dataTransferTypes',
		lookupObjectName: 'DATATRANSFERTYPES',
		tableHeaders: [
			option('dataTransferTypeId', 'ID'),
			option('dataTransferTypeName', 'Schedule Type Name'),
			action
		],
		itemMapper
	},
	[CONTACTS]: {
		singularText: 'Contact',
		pluralText: 'Contacts',
		modalComponentName: 'ContactForm',
		idKey: 'contactId',
		nameKey: 'contactFullName',
		optionsIdKey: 'contacts',
		typeId: 'contacts',
		lookupObjectName: 'CONTACTS',
		tableHeaders: contactHeaders,
		itemMapper
	},
	[LEGAL_BASIS]: {
		singularText: 'Legal Basis',
		pluralText: 'Legal Basis',
		modalComponentName: 'BasicForm',
		idKey: 'legalBasisId',
		nameKey: 'legalBasisName',
		optionsIdKey: 'legalBasis',
		typeId: 'legalBasis',
		lookupObjectName: 'LEGALBASIS',
		tableHeaders: [
			option('legalBasisId', 'ID'),
			option('legalBasisName', 'Legal Basis Name'),
			brand,
			createdDate,
			action
		],
		itemMapper
	},
	[ORGANISATIONS]: {
		singularText: 'Organisation',
		pluralText: 'Organisations',
		modalComponentName: 'OrganisationForm',
		idKey: 'organisationId',
		nameKey: 'organisationName',
		optionsIdKey: 'organisations',
		typeId: 'organisations',
		lookupObjectName: 'ORGANISATIONS',
		tableHeaders: [
			option('organisationId', 'ID'),
			option('organisationName', 'Organisation Name'),
			option('organisationTypeName', 'Type'),
			brand,
			createdDate,
			action
		],
		itemMapper
	},
	[ORGANISATION_TYPES]: {
		singularText: 'Organisation Type',
		pluralText: 'Organisation Types',
		modalComponentName: 'NameOnlyForm',
		idKey: 'organisationTypeId',
		nameKey: 'organisationTypeName',
		optionsIdKey: 'organisationTypes',
		typeId: 'organisationTypes',
		lookupObjectName: 'ORGANISATIONTYPES',
		tableHeaders: [
			option('organisationTypeId', 'ID'),
			option('organisationTypeName', 'Organisation Type Name'),
			action
		],
		itemMapper
	},
	[PII_CATEGORIES]: {
		singularText: 'PII Category',
		pluralText: 'PII Categories',
		modalComponentName: 'BasicForm',
		idKey: 'piiCategoryId',
		nameKey: 'piiCategoryName',
		optionsIdKey: 'piiCategories',
		typeId: 'pIICategories',
		lookupObjectName: 'PIICATEGORIES',
		tableHeaders: [
			option('piiCategoryId', 'ID'),
			option('piiCategoryName', 'PII Category Name'),
			brand,
			createdDate,
			action
		],
		itemMapper
	},
	[PROCESS_OWNERS]: {
		singularText: 'Process Owner',
		pluralText: 'Process Owners',
		modalComponentName: 'BasicForm',
		idKey: 'processOwnerId',
		nameKey: 'processOwnerName',
		optionsIdKey: 'processOwners',
		typeId: 'processOwners',
		lookupObjectName: 'PROCESSOWNERS',
		tableHeaders: [
			option('processOwnerId', 'ID'),
			option('processOwnerName', 'Process Owner Name'),
			brand,
			createdDate,
			action
		],
		itemMapper
	},
	[PROCESS_PURPOSES]: {
		singularText: 'Process Purpose',
		pluralText: 'Process Purposes',
		modalComponentName: 'ProcessPurposeForm',
		idKey: 'processPurposeId',
		nameKey: 'processPurposeName',
		optionsIdKey: 'processPurposes',
		typeId: 'processPurposes',
		lookupObjectName: 'PROCESSPURPOSES',
		tableHeaders: [
			option('processPurposeId', 'ID'),
			option('processPurposeName', 'Process Purpose Name'),
			brand,
			createdDate,
			action
		],
		itemMapper
	},
	[RETENTION_PERIODS]: {
		singularText: 'Retention Period',
		pluralText: 'Retention Periods',
		modalComponentName: 'BasicForm',
		idKey: 'retentionPeriodId',
		nameKey: 'retentionPeriodName',
		optionsIdKey: 'retentionPeriods',
		typeId: 'retentionPeriods',
		lookupObjectName: 'RETENTIONPERIODS',
		tableHeaders: [
			option('retentionPeriodId', 'ID'),
			option('retentionPeriodName', 'Retention Period'),
			brand,
			createdDate,
			action
		],
		itemMapper
	},
	[SPECIAL_PII_CATEGORIES]: {
		singularText: 'Special PII Category',
		pluralText: 'Special PII Categories',
		modalComponentName: 'BasicForm',
		idKey: 'specialPiiCategoryId',
		nameKey: 'specialPiiCategoryName',
		optionsIdKey: 'specialPiiCategories',
		typeId: 'specialPIICategories',
		lookupObjectName: 'SPECIALPIICATEGORIES',
		tableHeaders: [
			option('specialPiiCategoryId', 'ID'),
			option('specialPiiCategoryName', 'Special PII Category Name'),
			brand,
			createdDate,
			action
		],
		itemMapper
	},
	[SECURITY_MEASURE_TYPES]: {
		singularText: 'Security Measure Type',
		pluralText: 'Security Measure Types',
		modalComponentName: 'NameOnlyForm',
		idKey: 'securityMeasureTypeId',
		nameKey: 'securityMeasureTypeName',
		optionsIdKey: 'securityMeasureTypes',
		typeId: 'securityMeasureTypes',
		lookupObjectName: 'SECURITYMEASURETYPES',
		tableHeaders: [
			option('securityMeasureTypeId', 'ID'),
			option('securityMeasureTypeName', 'Security Measure Type Name'),
			action
		],
		itemMapper
	},
	[SCHEDULE_TYPES]: {
		singularText: 'Schedule Type',
		pluralText: 'Schedule Types',
		modalComponentName: 'NameOnlyForm',
		idKey: 'scheduleTypeId',
		nameKey: 'scheduleTypeName',
		optionsIdKey: 'scheduleTypes',
		typeId: 'scheduleTypes',
		lookupObjectName: 'SCHEDULETYPES',
		tableHeaders: [
			option('scheduleTypeId', 'ID'),
			option('scheduleTypeName', 'Schedule Type Name'),
			action
		],
		itemMapper
	},
	[SYSTEM_TYPES]: {
		singularText: 'System Type',
		pluralText: 'System Types',
		modalComponentName: 'NameOnlyForm',
		idKey: 'systemTypeId',
		nameKey: 'systemTypeName',
		optionsIdKey: 'systemTypes',
		typeId: 'systemTypes',
		lookupObjectName: 'SYSTEMTYPES',
		tableHeaders: [
			option('systemTypeId', 'ID'),
			option('systemTypeName', 'System Type Name'),
			action
		],
		itemMapper
	},
	[SYSTEMS]: {
		singularText: 'System',
		pluralText: 'Systems',
		modalComponentName: 'SystemForm',
		idKey: 'systemId',
		nameKey: 'systemName',
		optionsIdKey: 'systems',
		typeId: 'systems',
		lookupObjectName: 'SYSTEMS',
		tableHeaders: [
			option('systemId', 'ID'),
			option('systemName', 'System Name'),
			option('isInternal', 'Is Internal?'),
			option('systemTypeName', 'Type'),
			option('organisationName', 'Organisation'),
			option('cityName', 'City'),
			option('retentionPeriodName', 'Retention Period'),
			option('dpoContactName', 'DPO'),
			option('contactName', 'Contact'),
			brand,
			createdDate,
			action
		],
		itemMapper
	}
}
