<template>
	<div class="cassie-vertical-md">
		<BasicForm
			:item="item"
			:name-key="nameKey"
			@update:item="updateItem"
		/>
		<v-radio-group
			:value="item.isInternal"
			hide-details
			class="mt-0"
			@change="updateItemProperty('isInternal', $event)"
		>
			<template #label>
				Is an Internal System?
			</template>
			<div class="d-flex">
				<v-radio
					label="Yes"
					:value="true"
					class="my-0 mr-4"
				/>
				<v-radio
					label="No"
					:value="false"
					class="my-0"
				/>
			</div>
		</v-radio-group>
		<Dropdown
			:value="item.systemTypeId"
			:items="systemTypes.map(({ systemTypeId, systemTypeName }) => ({ value: systemTypeId, text: systemTypeName }))"
			label="Type *"
			rules="required"
			@input="updateItemProperty('systemTypeId', $event)"
		/>
		<Dropdown
			:value="item.organisationId"
			:items="organisations.map(({ organisationId, organisationName }) => ({ value: organisationId, text: organisationName }))"
			label="Organisation *"
			rules="required"
			@input="updateItemProperty('organisationId', $event)"
		/>
		<CitySelector
			:country-id="item.countryId"
			:city-id="item.cityId"
			:countries="countries"
			required
			@update:countryId="updateItemProperty('countryId', $event)"
			@update:cityId="updateItemProperty('cityId', $event)"
		/>
		<Dropdown
			:value="item.retentionPeriodId"
			:items="retentionPeriods.map(({ retentionPeriodId, retentionPeriodName }) => ({ value: retentionPeriodId, text: retentionPeriodName }))"
			label="Retention Period *"
			rules="required"
			@input="updateItemProperty('retentionPeriodId', $event)"
		/>
		<Dropdown
			:value="item.cassieTypeId"
			:items="cassieSubSystemTypes.map(({ cassieSubSystemTypeId, cassieSubSystemTypeName }) => ({ value: cassieSubSystemTypeId, text: cassieSubSystemTypeName }))"
			label="Cassie Type Id *"
			rules="required"
			@input="updateItemProperty('cassieTypeId', $event)"
		/>
		<Dropdown
			:value="item.dpoContactId"
			:items="contactOptions"
			label="Data Protection Officer (DPO) *"
			rules="required"
			@input="updateItemProperty('dpoContactId', $event)"
		/>
		<Dropdown
			:value="item.contactId"
			:items="contactOptions"
			label="Contact *"
			rules="required"
			@input="updateItemProperty('contactId', $event)"
		/>
	</div>
</template>

<script>
import BasicForm from './basic-form.vue'
import { getProcessesAndLookupData } from '../../../../../../shared/utils/api/processing.js'
import Dropdown from '../../../../../../shared/components/dropdown.vue'
import CitySelector from '../city-selector.vue'

export default {
	components: { CitySelector, Dropdown, BasicForm },
	props: {
		item: Object,
		nameKey: String,
		updateItem: Function,
		updateItemProperty: Function
	},
	data () {
		return {
			cassieSubSystemTypes: [],
			organisations: [],
			retentionPeriods: [],
			contacts: [],
			systemTypes: [],
			countries: []
		}
	},
	computed: {
		contactOptions () {
			return this.contacts.map(({ contactId, contactFullName }) => ({ value: contactId, text: contactFullName }))
		}
	},
	async created () {
		const { data: { lookUpData: { cassieSubSystemTypes, organisations, retentionPeriods, contacts, systemTypes, countries } } } = await getProcessesAndLookupData()
		this.cassieSubSystemTypes = cassieSubSystemTypes
		this.organisations = organisations
		this.retentionPeriods = retentionPeriods
		this.contacts = contacts
		this.systemTypes = systemTypes
		this.countries = countries
	}
}
</script>
