<template>
	<validation-observer
		#default="{ handleSubmit }"
		tag="div"
	>
		<Modal width="800px">
			<template #modal-title>
				{{ modalTitle }}
			</template>
			<template #modal-content>
				<component
					:is="lookUpDataConfigMap[type].modalComponentName"
					:item.sync="item"
					:name-key="lookUpDataConfigMap[type].nameKey"
					:update-item="updateItem"
					:update-item-property="updateItemProperty"
				/>
			</template>
			<template #modal-footer>
				<SecondaryActionButton @click="close">
					Cancel
				</SecondaryActionButton>
				<PrimaryActionButton @click="handleSubmit(submit)">
					{{ isEdit ? 'Save' : 'Create' }}
				</PrimaryActionButton>
			</template>
		</Modal>
	</validation-observer>
</template>

<script>
import Modal from '../../../../../../shared/components/modal.vue'
import SecondaryActionButton from '../../../../../../shared/components/secondary-action-button.vue'
import PrimaryActionButton from '../../../../../../shared/components/primary-action-button.vue'
import BasicForm from './basic-form.vue'
import NameOnlyForm from './name-only-form.vue'
import ContactForm from './contact-form.vue'
import OrganisationForm from './organisation-form.vue'
import ProcessPurposeForm from './process-purpose-form.vue'
import SystemForm from './system-form.vue'

import { lookUpDataConfigMap } from './look-up-data-map.js'
export default {
	components: { PrimaryActionButton, SecondaryActionButton, Modal, BasicForm, NameOnlyForm, ContactForm, OrganisationForm, ProcessPurposeForm, SystemForm },
	props: {
		type: String,
		itemToEdit: Object
	},
	data () {
		return {
			item: JSON.parse(JSON.stringify(this.itemToEdit || {})),
			lookUpDataConfigMap
		}
	},
	computed: {
		isEdit () {
			return Boolean(this.itemToEdit)
		},
		modalTitle () {
			return `${this.isEdit ? 'Edit' : 'Add'} ${lookUpDataConfigMap[this.type].singularText}`
		}
	},
	methods: {
		close () {
			this.$emit('close')
		},
		submit () {
			this.$emit('submit', this.item)
			this.close()
		},
		updateItemProperty (property, value) {
			this.updateItem({
				...this.item,
				[property]: value
			})
		},
		updateItem (item) {
			this.item = item
		}
	}
}
</script>
