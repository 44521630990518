<template>
	<ProcessingLayout>
		<template #content>
			<div class="d-flex align-center cassie-horizontal-md">
				<SecondaryActionButton
					:input-value="showFilters"
					@click="showFilters = !showFilters"
				>
					<v-icon>
						mdi-filter
					</v-icon>
					Filter
				</SecondaryActionButton>
				<template v-if="showFilters">
					<TextField
						v-model="searchQuery"
						label="Search"
						append-icon="mdi-magnify"
					/>
					<Dropdown
						v-model="selectedBrandId"
						:label="'Brand' | useLabels"
						:items="brandOptions"
						custom-sort
					/>
				</template>
			</div>
			<SectionCard>
				<template #title>
					Look up data
				</template>
				<template
					v-if="selectedLookUpType"
					#title-action
				>
					<TextButton @click="showAddModal = true">
						<v-icon>
							mdi-plus
						</v-icon>
						{{ addButtonText }}
					</TextButton>
				</template>
				<template #body>
					<Dropdown
						v-model="selectedLookUpType"
						:items="lookUpOptions"
						label="Select Data Type"
						class="cassie-input-width-md"
					/>
					<DataTable
						v-if="selectedLookUpType"
						:items="tableItems"
						:headers="tableHeaders"
						@click:row="rowClick"
					>
						<template #item.action="{ item }">
							<IconButton
								:tooltip-text="`Edit ${singularText}`"
								@click="itemToEdit = item"
							>
								mdi-pencil
							</IconButton>
							<IconButton
								:tooltip-text="`Delete ${singularText}`"
								@click.stop.prevent="itemToRemove = item"
							>
								mdi-trash-can
							</IconButton>
						</template>
					</DataTable>
				</template>
			</SectionCard>
			<ConfirmDeleteModal
				v-if="itemToRemove"
				:entity-name="itemToRemove[lookUpDataConfigMap[selectedLookUpType].nameKey]"
				:entity-type="lookUpDataConfigMap[selectedLookUpType].singularText"
				@close="itemToRemove = null"
				@delete="removeItem"
			/>
			<ManageLookUpDataModal
				v-if="showAddModal || itemToEdit"
				:type="selectedLookUpType"
				:item-to-edit="itemToEdit"
				@close="() => {
					showAddModal = false
					itemToEdit = null
				}"
				@submit="saveLookUpDataChanges"
			/>
		</template>
	</ProcessingLayout>
</template>
<script>
import ProcessingLayout from '../processing-layout.vue'
import SectionCard from '../../../../../../shared/components/section-card.vue'
import TextButton from '../../../../../../shared/components/text-button.vue'
import { lookUpDataConfigMap } from './look-up-data-map.js'
import Dropdown from '../../../../../../shared/components/dropdown.vue'
import { getProcessesAndLookupData, upsertLookupData, deleteLookUpData } from '../../../../../../shared/utils/api/processing.js'
import DataTable from '../../../../../../shared/components/data-table.vue'
import ConfirmDeleteModal from '../../../../../../shared/components/confirm-delete-modal.vue'
import ManageLookUpDataModal from './manage-look-up-data-modal.vue'
import { showSnackbar } from '../../../../../../shared/state/snackbar.js'
import { brandOptions } from '../../../../../../shared/state/brands.js'
import SecondaryActionButton from '../../../../../../shared/components/secondary-action-button.vue'
import IconButton from '../../../../../../shared/components/icon-button.vue'
import TextField from '../../../../../../shared/components/text-field.vue'
export default {
	components: {
		TextField,
		SecondaryActionButton,
		ManageLookUpDataModal,
		ConfirmDeleteModal,
		DataTable,
		Dropdown,
		TextButton,
		SectionCard,
		ProcessingLayout,
		IconButton
	},
	setup () {
		return {
			brandOptions
		}
	},
	data () {
		return {
			showFilters: false,
			searchQuery: null,
			selectedBrandId: null,
			selectedLookUpType: null,
			lookUpData: {},
			itemToEdit: null,
			itemToRemove: null,
			showAddModal: false,
			lookUpDataConfigMap
		}
	},
	computed: {
		lookUpOptions () {
			return Object.keys(lookUpDataConfigMap).map(key => ({ value: key, text: lookUpDataConfigMap[key].pluralText }))
		},
		tableItems () {
			const typeConfig = lookUpDataConfigMap[this.selectedLookUpType]
			return (this.lookUpData[typeConfig.optionsIdKey]?.map(typeConfig.itemMapper) || []).filter(item => {
				let check = true
				if (this.searchQuery) check = item[typeConfig.nameKey].toLowerCase().includes(this.searchQuery.toLowerCase())
				if (this.selectedBrandId !== null) check = check && this.selectedBrandId === item.brandId
				return check
			})
		},
		tableHeaders () {
			return lookUpDataConfigMap[this.selectedLookUpType].tableHeaders || []
		},
		addButtonText () {
			return `Add ${lookUpDataConfigMap[this.selectedLookUpType].singularText}`
		},
		optionsIdKey () {
			return lookUpDataConfigMap[this.selectedLookUpType].optionsIdKey
		},
		idKey () {
			return lookUpDataConfigMap[this.selectedLookUpType].idKey
		},
		selectedLookupTypeId () {
			return lookUpDataConfigMap[this.selectedLookUpType].typeId
		},
		singularText () {
			return lookUpDataConfigMap[this.selectedLookUpType].singularText
		}
	},
	created () {
		this.loadLookUpData()
	},
	methods: {
		async loadLookUpData () {
			const { data: { lookUpData } } = await getProcessesAndLookupData()
			this.lookUpData = []
			this.lookUpData = lookUpData
		},
		rowClick (row) {
			this.itemToEdit = row
		},
		async removeItem () {
			this.lookUpData[this.optionsIdKey] = this.lookUpData[this.optionsIdKey].filter(item => item[this.idKey] !== this.itemToRemove[this.idKey])
			const deletedLookupData = {
				id: this.itemToRemove[this.idKey],
				lookupObject: this.selectedLookupTypeId
			}
			await deleteLookUpData(deletedLookupData)
			showSnackbar(`You have removed this ${this.singularText}`)
			this.itemToRemove = null
			await this.loadLookUpData()
		},
		async saveLookUpDataChanges (item) {
			const isEdit = Boolean(this.itemToEdit)
			const optionsIdKey = this.optionsIdKey
			const idKey = this.idKey
			let list = this.lookUpData[optionsIdKey]
			if (list === null) {
				this.lookUpData[optionsIdKey] = []
				list = this.lookUpData[optionsIdKey]
			}
			const itemIndexToReplace = list.findIndex(listItem => listItem[idKey] === item[idKey])
			if (itemIndexToReplace === -1) {
				list.push(item)
			} else {
				list[itemIndexToReplace] = item
			}
			this.lookUpData[optionsIdKey] = list
			// await updateLookUpData(this.lookUpData)
			await upsertLookupData(this.lookUpData)
			if (isEdit) {
				showSnackbar(`You have updated this ${this.singularText}`)
			} else {
				showSnackbar(`You have created a new ${this.singularText}`)
			}
			this.itemToEdit = null
			setTimeout(this.loadLookUpData, 500)
		}
	}
}
</script>
