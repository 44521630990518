<template>
	<div class="cassie-vertical-md">
		<BasicForm
			:item="item"
			:name-key="nameKey"
			@update:item="updateItem"
		/>
	</div>
</template>

<script>
import BasicForm from './basic-form.vue'

export default {
	components: { BasicForm },
	props: {
		item: Object,
		nameKey: String,
		updateItem: Function,
		updateItemProperty: Function
	}
}
</script>
