<template>
	<div class="cassie-vertical-md">
		<BasicForm
			:item="item"
			:name-key="nameKey"
			@update:item="updateItem"
		/>
		<TextField
			:value="item.jobTitle"
			label="Job Title *"
			rules="required"
			@input="updateItemProperty('jobTitle', $event)"
		/>
		<TextField
			:value="item.emailAddress"
			label="Email Address *"
			rules="required"
			@input="updateItemProperty('emailAddress', $event)"
		/>
		<TextField
			:value="item.telNo"
			label="Telephone No. *"
			rules="required"
			@input="updateItemProperty('telNo', $event)"
		/>
		<TextField
			:value="item.mobileNo"
			label="Cell/Mobile No. *"
			rules="required"
			@input="updateItemProperty('mobileNo', $event)"
		/>
		<TextArea
			:value="item.address"
			label="Address *"
			rules="required"
			@input="updateItemProperty('address', $event)"
		/>
		<Dropdown
			:value="item.organisationId"
			:items="organisationItems"
			label="Organisation *"
			@input="updateItemProperty('organisationId', $event)"
		/>
		<CitySelector
			:country-id="item.countryId"
			:city-id="item.cityId"
			:countries="countries"
			required
			@update:countryId="updateItemProperty('countryId', $event)"
			@update:cityId="updateItemProperty('cityId', $event)"
		/>
		<v-radio-group
			:value="item.isDPO"
			hide-details
			class="mt-0"
			mandatory
			@change="updateItemProperty('isDPO', $event)"
		>
			<template #label>
				Is Data Protection Office (DPO)?
			</template>
			<div class="d-flex">
				<v-radio
					label="Yes"
					:value="true"
					class="my-0 mr-4"
				/>
				<v-radio
					label="No"
					:value="false"
					class="my-0"
				/>
			</div>
		</v-radio-group>
		<v-radio-group
			:value="item.isExternal"
			hide-details
			class="mt-0"
			mandatory
			@change="updateItemProperty('isExternal', $event)"
		>
			<template #label>
				Is External?
			</template>
			<div class="d-flex">
				<v-radio
					label="Yes"
					:value="true"
					class="my-0 mr-4"
				/>
				<v-radio
					label="No"
					:value="false"
					class="my-0"
				/>
			</div>
		</v-radio-group>
	</div>
</template>

<script>
import BasicForm from './basic-form.vue'
import TextField from '../../../../../../shared/components/text-field.vue'
import TextArea from '../../../../../../shared/components/textarea.vue'
import CitySelector from '../city-selector.vue'
import { getProcessesAndLookupData } from '../../../../../../shared/utils/api/processing.js'
import Dropdown from '../../../../../../shared/components/dropdown.vue'
export default {
	components: { TextArea, TextField, BasicForm, CitySelector, Dropdown },
	props: {
		item: Object,
		nameKey: String,
		updateItem: Function,
		updateItemProperty: Function
	},
	data () {
		return {
			countries: [],
			organisations: []
		}
	},
	computed: {
		organisationItems () {
			return this.organisations.map(({ organisationId, organisationName }) => ({
				value: organisationId,
				text: organisationName
			}))
		}
	},
	async created () {
		const { data: { lookUpData: { countries, organisations } } } = await getProcessesAndLookupData()
		this.countries = countries
		this.organisations = organisations
	}
}
</script>
